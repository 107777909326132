import { EFontWeight, EFonts } from 'src/config/enums';
import styled, { css } from 'styled-components';

import { OxContainer } from 'src/components/OxContainer';
import { createTextStyle } from 'src/utils/createTextStyle';
import { fluidSizing } from 'src/utils';
import { transparentize } from 'polished';

export const Container = styled.div(
    ({ theme }) => css`
        /* height: 100%; */
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        p {
            text-align: center;
            ${fluidSizing([
                { prop: 'max-width', values: [0, 320, 320] },
                { prop: 'padding-top', values: [8, 10, 10] },
                { prop: 'padding-bottom', values: [8, 10, 10] }
            ])}
        }
        & > * {
            text-align: center;
        }
        & p {
            font-weight: ${theme.fontWeights.medium};
        }
        ${fluidSizing([{ prop: 'padding-top', values: [20, 40, 40] }])}
    `
);

export const Heading = styled.h1`
    ${createTextStyle(EFonts.Hatton, EFontWeight.Medium, [30, 35, 35], [35, 38, 38])}
`;

export const Subtitle = styled.h4`
    ${createTextStyle(EFonts.Montserrat, EFontWeight.Normal, [14, 14, 14], [20, 20, 20])}

    ${fluidSizing([
        { prop: 'max-width', values: [null, 475, 475] },
        { prop: 'padding-top', values: [25, 25, 25] },
        { prop: 'padding-bottom', values: [25, 25, 25] }
    ])}
`;

export const Paragraph = styled.p`
    text-transform: capitalize;

    ${createTextStyle(EFonts.Montserrat, EFontWeight.Medium, [14, 14, 14], [18, 18, 18])}
`;

export const Summary = styled.div(
    ({ theme }) => css`
        width: 100%;
        background-color: ${transparentize(0.5, theme.colors.basic.white)};
        display: flex;
        flex-direction: column;
        align-items: center;
        ${fluidSizing([
            { prop: 'padding-left', values: [25, 100, 100] },
            { prop: 'padding-right', values: [25, 100, 100] },
            { prop: 'padding-top', values: [25, 25, 25] },
            { prop: 'padding-bottom', values: [25, 25, 25] },
            { prop: 'max-width', values: [null, 475, 475] }
            // { prop: "height", values: [320, 320, 320] }
        ])}
        & > *:first-child {
            font-weight: ${theme.fontWeights.semiBold};
            ${fluidSizing([{ prop: 'padding-bottom', values: [20, 20, 20] }])}
        }
    `
);

export const SummaryData = styled.div(
    ({ theme }) => css`
        width: 100%;
        display: grid;
        grid-template-columns: 35% 65%;
        grid-template-rows: auto auto auto;
        justify-items: start;
        p {
            text-align: left;
            white-space: pre;
        }
    `
);

export const Footer = styled(OxContainer)`
    span {
        ${createTextStyle(EFonts.Montserrat, EFontWeight.Light, [10, 10, 10], [18, 18, 18])}
    }
    ${fluidSizing([{ prop: 'margin-top', values: [10, 10, 10] }])}
`;
