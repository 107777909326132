import { EFontWeight, EFonts } from 'src/config/enums';
import styled, { css } from 'styled-components';

import { OxButton } from 'src/components/OxButton';
import { OxCheckbox } from '../OxCheckbox';
import { OxContainer } from 'src/components/OxContainer';
import { OxImage } from 'src/components/OxImage';
import { OxInput } from 'src/components/OxInput';
import { OxLink } from 'src/components/OxLink';
import { createTextStyle } from 'src/utils/createTextStyle';
import { fluidSizing } from 'src/utils';

export const Container = styled.div`
    position: relative;
    max-width: 100vw;
    ${fluidSizing([{ prop: 'padding-bottom', values: [28, 56, 75] }])}
`;

export const ContactUsContainer = styled(OxContainer)<{ isSuccess: boolean }>(
    ({ isSuccess }) => css`
        display: flex;
        flex-direction: column;
        ${isSuccess &&
        css`
            ${fluidSizing([{ prop: 'height', values: [620, 600, 600] }])}
            overflow: hidden;
        `}
    `
);

export const Title = styled.div(
    ({ theme }) => css`
        color: ${theme.colors.primary.main};
        ${createTextStyle(EFonts.Montserrat, EFontWeight.Bold, [10, 10, 10], [18, 18, 18])}
        text-transform: uppercase;
        ${fluidSizing([
            { prop: 'margin-bottom', values: [10, 10, 10] },
            { prop: 'margin-top', values: [20, 20, 20] }
        ])}
    `
);

export const BlockText = styled.div`
    display: block;
    ${fluidSizing([{ prop: 'margin-bottom', values: [20, 25, 25] }])}
    ${createTextStyle(EFonts.Hatton, EFontWeight.SemiBold, [14, 21, 24], [22, 32, 38])}
`;

export const Link = styled(OxLink)(
    ({ theme }) => css`
        display: contents;
        color: ${theme.colors.primary.main};
        ${fluidSizing([
            { prop: 'margin-left', values: [2, 2, 2] },
            { prop: 'margin-right', values: [3, 3, 3] }
        ])}
    `
);

export const ImageFormContainer = styled.div(
    ({ theme }) => css`
        display: grid;
        grid-template-columns: 1fr 1fr;
        ${fluidSizing([
            { prop: 'margin-bottom', values: [10, 10, 10] },
            { prop: 'gap', values: [null, 40, 40] }
        ])}
        width: 100%;
        ${theme.breakpoints.only('xs')} {
            display: block;
        }
    `
);

export const FormContainer = styled.div`
    display: flex;
    flex-direction: column;
`;

export const CustomImage = styled(OxImage)(
    ({ theme }) => css`
        ${theme.breakpoints.only('xs')} {
            display: none;
        }
    `
);

export const FormWrapper = styled.div`
    flex: 1;
    form > *:not(:last-child) {
        ${fluidSizing([{ prop: 'margin-bottom', values: [15, 15, 15] }])}
    }
    transition: 800ms;
`;

export const Query = styled(OxInput)`
    input {
        ${fluidSizing([{ prop: 'height', values: [80, 80, 80] }])}
    }
`;

export const CallBack = styled(OxButton)`
    width: 100%;
`;

export const Checkbox = styled(OxCheckbox)`
    ${createTextStyle(EFonts.Montserrat, EFontWeight.Light, [12, 12, 13], [18, 18, 20])}
`;

export const RatingLabel = styled.div(
    ({ theme }) => css`
        color: ${theme.colors.basic.black};
        ${createTextStyle(EFonts.Montserrat, EFontWeight.Normal, [12, 12, 13], [18, 18, 20])}
    `
);

export const RatingStart = styled.div`
    ${createTextStyle(EFonts.Montserrat, EFontWeight.Normal, [20, 20, 20], [20, 20, 20])}
    ${fluidSizing([{ prop: 'padding-top', values: [10, 10, 10] }])}
`;
