import * as Styled from './OxFlex.styled';

import React, { HTMLAttributes } from 'react';

export type TOxFlexProps = HTMLAttributes<HTMLDivElement> & {
    isItem?: boolean;
    flexDirectionRow?: boolean;
    flexDirectionColumn?: boolean;
    justifyContentStart?: boolean;
    justifyContentEnd?: boolean;
    justifyContentCenter?: boolean;
    justifyContentBetween?: boolean;
    justifyContentAround?: boolean;
    wrap?: boolean;
    wrapReverse?: boolean;
    nowrap?: boolean;
    alignItemsStretch?: boolean;
    alignItemsCenter?: boolean;
    alignItemsStart?: boolean;
    alignItemsEnd?: boolean;
    alignItemsBaseline?: boolean;
    // Child Props
    flexGrow?: number;
    flexShrink?: number;
    flexBasis?: number;
    flex?: string;
};

export const OxFlex = ({ children, ...rest }: SCProps<'div', TOxFlexProps>): JSX.Element => {
    return <Styled.FlexboxWrapper {...rest}>{children}</Styled.FlexboxWrapper>;
};
