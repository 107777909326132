import { OxPageHelmet } from 'src/components/OxPageHelmet';
import React from 'react';
import { OxFeedback } from 'src/components/OxFeedback';
import { WindowLocation } from '@reach/router';

type TProps = {
    location?: WindowLocation;
};

const FeedbackPage = ({ location }: TProps): JSX.Element => {
    return (
        <>
            <OxPageHelmet
                title="Feedback | Luxury Facial Aesthetics"
                description="Face your future beautifully, London. Book a consultation with one of our best in class aesthetic doctors to discuss your hopes and concerns. Together co-create a treatment plan resulting in a fresh natural looking you."
                keywords="consultation, aesthetic doctors, clinic, "
            />
            <OxFeedback location={location} />
        </>
    );
};

export default FeedbackPage;
